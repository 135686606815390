export const formatRegularLinks = links => {
  return (
    links &&
    links
      .filter(
        item =>
          !!(
            item.nav_item &&
            item.nav_item[0] &&
            item.nav_item[0].spans &&
            item.nav_item[0].spans[0].data &&
            item.nav_item[0].spans[0].data.url &&
            item.nav_item[0].text
          )
      )
      .map(item => ({
        url: item.nav_item[0].spans[0].data.url.includes("https://[site]/")
          ? item.nav_item[0].spans[0].data.url.replace("https://[site]/", "/")
          : item.nav_item[0].spans[0].data.url,
        type: item.nav_item[0].spans[0].data.url.includes("https://[site]/")
          ? "internal"
          : "external",
        label: item.nav_item[0].text,
      }))
  )
}
