import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import { RichText } from "prismic-reactjs"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import CareersContent from "../components/CareersContent"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"

export const query = graphql`
  {
    prismic {
      allCareerss {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            main_content
            intro_text
            body {
              ... on PRISMIC_CareersBodyBenefits {
                primary {
                  section_title
                }
                fields {
                  card_benefits
                  card_title
                }
                type
              }
            }
            address
            link_to_opportunities_page
            top_sub_nav {
              nav_item
            }
            side_content_title
            include_opportunities_button_on_side_panel
          }
        }
      }
    }
  }
`

const links = [
  {
    key: "about",
    stub: "/about",
    displayName: "About",
  },
  {
    key: "team",
    stub: "/team",
    displayName: "Team",
  },
  {
    key: "capabilities",
    stub: "/capabilities",
    displayName: "Capabilities",
  },
  {
    key: "culture",
    stub: "/culture",
    displayName: "Culture",
  },
  {
    key: "careers",
    stub: "/careers",
    displayName: "Careers",
  },
]

const StyledContentSection = styled(ContentSection)``

const renderComponent = data => {
  const doc = data.prismic.allCareerss.edges.slice(0, 1).pop()
  if (!doc) return null

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  const regularLinks = formatRegularLinks(doc.node.top_sub_nav)

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        regularLinks={regularLinks}
      />
      <StyledContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <CareersContent doc={doc} />
            </div>
          </TwoColContainer>
        </div>
      </StyledContentSection>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
