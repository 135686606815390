import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import IconDoubleArrows from "../images/double-arrows.svg"

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    display: block;
  }

  .main-content {
    flex: 1 0 500px;
    margin-right: 2rem;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .open-positions {
    .action-button {
      display: inline-block;
      background: #0099cb;
      padding: 1rem 1.25rem;
      border-radius: 3px;
      margin: 1rem 0;
      text-decoration: none;
      color: white;
      transition: box-shadow 0.2s ease, padding 0.5s ease;
      font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
      display: inline-flex;
      align-items: center;

      .text {
        flex: 0 0 auto;
        margin-right: 2rem;
      }

      .arrows {
        flex: 0 0 auto;
        transform: translateY(1px);
      }

      &:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .benefits {
    margin: 3rem 0;
  }

  .side-content {
    flex: 0 1 250px;
    position: sticky;
    top: 180px;

    @media screen and (max-width: 600px) {
      width: 100%;
      position: relative;
      top: 0;
    }
  }

  .contact-card {
    background: #fcfcfc;
    padding: 1rem;

    h3 {
      font-size: 1.2rem;
    }

    .address p {
      font-size: 0.9rem;
      color: #63666a;
    }

    a {
      color: #0099cb;
      text-decoration: none;
    }
  }
`

const BenefitsCard = styled("div")`
  background-color: #fff;
  padding: 1rem;
  box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  margin-bottom: 1rem;

  h5 {
    margin: 0;
    font-size: 1.1rem;
  }

  .benefits-list {
    ul {
      padding: 0;
      padding-left: 1.1rem;
    }

    li {
      font-size: 0.9rem !important;
    }
  }
`

const ViewOpportunitiesButton = ({ link }) => {
  if (!link) return null
  const url =
    link[0] &&
    link[0].spans[0] &&
    link[0].spans[0].data &&
    link[0].spans[0].data.url
  const text = link[0] && link[0].text
  if (!url || !text) return null
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="action-button"
    >
      <span className="text">{text}</span>
      <IconDoubleArrows className="arrows" />
    </a>
  )
}

const CareersContent = ({ doc }) => {
  return (
    <Container>
      <div className="main-content">
        <h3 className="intro-text">{doc.node.intro_text[0].text}</h3>
        <div className="main-content">
          {RichText.render(doc.node.main_content)}
        </div>
        {!doc.node.include_opportunities_button_on_side_panel && (
          <div className="open-positions">
            <ViewOpportunitiesButton
              link={doc.node.link_to_opportunities_page}
            />
          </div>
        )}
        {doc.node.body
          .filter(item => item.type === "benefits")
          .map(item => (
            <div className="benefits">
              <h4>{item.primary.section_title[0].text}</h4>
              {item.fields.map(field => (
                <BenefitsCard>
                  <h5>{RichText.asText(field.card_title)}</h5>
                  <div className="benefits-list">
                    {RichText.render(field.card_benefits)}
                  </div>
                </BenefitsCard>
              ))}
            </div>
          ))}
      </div>
      <div className="side-content">
        <div className="contact-card">
          {doc.node.side_content_title &&
            RichText.render(doc.node.side_content_title)}
          <div className="address">{RichText.render(doc.node.address)}</div>
        </div>
        {doc.node.include_opportunities_button_on_side_panel && (
          <div className="open-positions">
            <ViewOpportunitiesButton
              link={doc.node.link_to_opportunities_page}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default CareersContent
