import React, { useContext } from "react"
import styled from "styled-components"
import { ContentSection } from "./ContentSection"
import { Link } from "gatsby"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"

const StickyContainer = styled("div")`
  position: sticky;
  top: 98px;
  z-index: 2;
`

const Container = styled(ContentSection)`
  background-color: #fcfcfc;
  border-bottom: solid 1px #eeeeee;
  margin: 0;

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  h2 {
    flex: 0 0 auto;
    margin: 0;
    font-weight: 400;

    a {
      text-decoration: none;
      color: #000;
      display: flex;
      align-items: center;

      svg {
        margin-top: 2px;
        margin-right: 5px;
      }

      &:hover {
        color: #0099cb;
        cursor: pointer;
      }
    }
  }

  .links-container {
    flex: 0 0 auto;

    @media screen and (max-width: 480px) {
      display: flex;
      flex-wrap: wrap;
    }

    .link-button {
      border: none;
      background: none;
      color: #87959a;
      transition: color 0.3s ease;
      font-size: 0.9rem;
      font-family: "Open Sans";
      margin: 0;
      padding: 0;
      margin-left: 2rem;
      text-decoration: none;
      text-align: left;

      &.active {
        color: #494c47;
        font-weight: 600;
      }

      &:hover {
        color: #0099cb;
        cursor: pointer;
      }

      @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`

const TopSubNav = ({
  title,
  titleLink,
  topScrollLinks,
  externalScrollLinks,
  regularLinks,
  ...rest
}) => {
  const [activeItems] = useContext(ActiveScrollContext)
  const firstActiveItem = activeItems
    ? Object.keys(activeItems).find(key => activeItems[key] === true)
    : ""

  function handleScroll(id) {
    const ref = document.getElementById(id)
    const rect = ref && ref.getBoundingClientRect()
    const bodyRect = document.body.getBoundingClientRect().top
    const top = !rect ? 0 : rect.top - bodyRect
    window.scroll({
      top,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <StickyContainer>
      <Container {...rest}>
        <div className="inner-container">
          {titleLink ? (
            <h2>
              <Link to={titleLink}>
                <ArrowBackIcon />
                <span>{title}</span>
              </Link>
            </h2>
          ) : (
            <h2>{title}</h2>
          )}
          <div className="links-container">
            {topScrollLinks &&
              topScrollLinks.map((l, i) => (
                <button
                  className={
                    l.id !== firstActiveItem
                      ? "link-button"
                      : "link-button active"
                  }
                  onClick={() => handleScroll(l.id)}
                >
                  {l.label}
                </button>
              ))}
            {externalScrollLinks &&
              externalScrollLinks.map(l => (
                <Link className="link-button" to={l.url}>
                  {l.label}
                </Link>
              ))}
            {regularLinks &&
              regularLinks.map(item => {
                return item.type === "internal" ? (
                  <Link to={item.url} className="link-button">
                    {item.label}
                  </Link>
                ) : (
                  <a
                    href={item.url}
                    className="link-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.label}
                  </a>
                )
              })}
          </div>
        </div>
      </Container>
    </StickyContainer>
  )
}

export default TopSubNav
