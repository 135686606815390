import styled from "styled-components"

export const ContentSection = styled("div")`
  margin: 2rem 0 3rem;
  padding: 1rem;
  display: flex;
  justify-content: center;

  .inner-container {
    max-width: 1200px;
    flex: 0 1 1200px;
  }
  /* 
  h3 {
    font-size: 2rem;
    margin-top: 0;
  } */

  h1 {
    font-family: "montserrat";
  }

  h3,
  h4 {
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: "montserrat";
  }

  p,
  ul,
  ol {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    line-height: 1.5;
    font-weight: 300;
  }

  .intro-text {
    font-size: 2rem;
    margin-top: 0;
    font-weight: 700;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  }
`
