import React from "react"
import styled from "styled-components"
import { Location } from "@reach/router"
import { Link } from "gatsby"

const Container = styled("nav")`
  background: #fcfcfc;
  padding: 1rem;
  position: sticky;
  top: 180px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 1rem;
    padding-left: 0;
    transition: padding 0.3s ease;

    a {
      color: #000000;
      text-decoration: none;
      transition: color 0.3s ease;
    }

    .active {
      color: #0099cb;
    }

    &:hover {
      padding-left: 0.5rem;

      a {
        color: #0099cb;
      }
    }
  }
`

const SideNav = ({ links, ...rest }) => {
  return (
    <Container {...rest}>
      <Location>
        {({ location }) => (
          <ul>
            {links &&
              links.map(l => {
                let isActive = location.pathname.startsWith(`${l.stub}`)
                return (
                  <li key={l.key}>
                    <Link to={l.stub} className={isActive ? "active" : ""}>
                      {l.displayName}
                    </Link>
                  </li>
                )
              })}
          </ul>
        )}
      </Location>
    </Container>
  )
}

export default SideNav
