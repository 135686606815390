import styled from "styled-components"

const TwoColContainer = styled("div")`
  display: flex;

  .left-col {
    flex: 1;
    margin-right: 3rem;
  }

  .right-col {
    flex: 3;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    flex-direction: column-reverse;

    .left-col {
      margin: 2rem 0;
    }
  }
`

export default TwoColContainer
